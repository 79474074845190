import { defineStore } from 'pinia';
import { ApiKey, fetchApi, FetchApiOrigin } from "~/common/API/baseApi";
export const useAppStore = defineStore('app', () => {
  const interests = ref<{ id: number, name: string }[]>([]);


  // Получаем все интересы из бд
  (async () => {
    if (interests.value.length > 0) return;
    try {
      const endpoint = '/place/interests';
      const response: any = await fetchApi({
        endpoint,
        origin: FetchApiOrigin.LIGHT_API_ORIGIN,
      });
      interests.value = response.interests;
    } catch (err) {
      console.error(err);
    }
  })();

  return {
    interests,
  }
});